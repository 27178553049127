import {
  DEFAULT_LANGUAGE,
  LANGUAGE_SLUGS,
  BOT_STORAGE_KEY,
  DEFAULT_LANGUAGE_SLUG,
} from '../constants'

export interface PathData {
  languageSlug: string
  language: string
  sessionId: string
  ivrException: boolean
  initialContent: string | undefined
  referral: string | undefined
  queueId: string | undefined
}
export interface InMemoryStorage {
  initialTimestamp: number
  activeConversation: boolean
  name: string
  email: string
  bookingNumber: string
  contactReason: string
  question: string
}

export function isProduction() {
  return process.env.REACT_APP_ENV === 'production'
}

export function getPathData(): PathData {
  const [langSlug, sessionId, ivrException] = window.location.pathname
    .split('/')
    .slice(2)
  const language = LANGUAGE_SLUGS[langSlug?.toLowerCase()]

  const urlParams = new URLSearchParams(window.location.search)
  const initialContent = urlParams.get('content') ?? undefined
  const referral = urlParams.get('ref') ?? undefined
  const queueId = urlParams.get('q') ?? undefined

  return {
    languageSlug: langSlug || DEFAULT_LANGUAGE_SLUG,
    language: language || DEFAULT_LANGUAGE,
    sessionId: sessionId ?? '',
    ivrException: ivrException?.toLowerCase() === 'x',
    initialContent,
    referral,
    queueId,
  }
}

export function getStorage(): InMemoryStorage {
  const storage = window.inMemoryStorage.getItem(BOT_STORAGE_KEY) || '{}'
  return JSON.parse(storage)
}
