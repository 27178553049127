import { HANDOFF_PAYLOAD, INITIAL_PAYLOAD } from "../constants"

export enum InitialContents {
    Advisor= 'advisor',
}

export type InitialPayloadParams = {
    queueId?: string
}

export function getInitialPayload(initialContent?: string, params?: InitialPayloadParams) {
    const { queueId } = params as InitialPayloadParams
    switch (initialContent) {
        case InitialContents.Advisor:
            return `${HANDOFF_PAYLOAD}|${JSON.stringify({ queueId })}`
        default:
            return INITIAL_PAYLOAD
    }
}
