import React from 'react'
import ReactDOM from 'react-dom'
import FaqBotApp from './app-faq'

ReactDOM.render(
  <React.StrictMode>
    <FaqBotApp />
  </React.StrictMode>,
  document.getElementById('root')
);
